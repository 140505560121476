.ui.menu{
    display: flex;
    justify-content: space-around;

    .item{
      font-size: 1.1rem;
      
      &:hover {
        transform: scale(1.1);

      }
    }
   
}

img.ui.image{
    
    position: center;
    &:hover {
     
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 2.2s; 
      
        /* When the animation is finished, start again */
        animation-iteration-count: infinite; 
      }
   
}