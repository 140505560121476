.userAccount.default{
    width: 25%;
    margin: 5% auto;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    box-shadow: 1px 1px 10px 2px black;
    &:hover{
        transform: scale(1.05);
    }
}

.userAccount.form{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding: 10px;

    .image{
        width: 50%;
    }

    .inputs{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
    }
    form{
        border: 1px solid black;
        border-radius: 5px;
        box-shadow: 5px 5px black;
        padding: 10px;

        input{
            background: rgb(236, 236, 236);
    
            &:hover{
                background: white;
            }
        }
    }
   
}