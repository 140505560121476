form.userAccount{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    button{
        width: 15%;
        padding: 2%;
        margin: 2%;        
    }
}