.addListing{
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    .profilePic{
        width: 50%;
        margin: 0 auto;
    }

    .error{
        color: red;
    }

    input, textarea{
        background: lightgrey;

        &:hover{
            background: white;
        }
    }

    button.ui.green.button.positive{
        margin: 2%;
        padding: 2%;
    }
}