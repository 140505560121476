.login {
  margin: 5% auto;
  width: 25rem;
  border: 2px solid grey;
  border-radius: 5px;
  font-size: 1.2rem;
  background:#e5e5e5;
  box-shadow: 5px 1px 20px #111;
  @media only screen and (max-width: 500px) {
    width: 70%
  }
  h3 {
    font-size: 2rem;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 2px;
    z-index: -1;
  }

  .field {
    display: flex;
    flex-flow: column wrap;

    width: 80%;
    margin: 0 auto;

    label {
      text-align: left;
      padding: 5px 0px;
    }
  }

  button.ui.green.button.positive{
    margin: 2%;
    padding: 2%;
  }
}
