.land-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  div.ui {
    border: 2px solid black;
    margin: 20px;
    margin-top: 0;
    margin-bottom: 5rem;
    color: blue;
    height: auto;
    background: #e5e5e5;
    box-shadow: 5px 1px 20px #111;
    font-size: 1.1rem;
    &:hover {
      transform: scale(1.05);
    }
  }

  //Last card
  .ui.card:last-child {
    margin-bottom: 5rem;
  }

  //first card
  .ui.card:first-child {
    margin-top: 0;
  }
}

.Land-List-Parent {
  h1 {
    margin-bottom: 3.5rem;
    margin-top: 2rem;
    color: black;
    font-size:3rem;
  }
}
.add-booking {
  background: #828282d2 !important;
  color: #252525 !important;
  font-size: 1rem !important;
  width: 6rem;
  border-radius: 8px !important;
  &:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 1.8s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
