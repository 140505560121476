div.ui.modal.transition.visible.active {
  img {
    width: 100%;
    border-radius: 10px;
  }

  .description {
    width: 75%;
    margin: 0 auto;

    .addListing {
      width: 100%;
      margin: 0 auto;

      .inputs {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        p {
          font-size: 1.5rem;
        }

        div {
          width: 45%;
        }
      }
    }
  }

  textarea {
    border-radius: 5px;
  }
}
.land-list-owner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 3% 0%;

  div.ui {
    width: 38%;
    border: 2px solid black;
    margin: 20px;
    margin-top: 0;
    margin-bottom: 5rem;
    color: blue;
    height: auto;
    background: #e5e5e5;
    box-shadow: 5px 1px 20px #111;
    font-size: 1.1rem;
    &:hover {
      transform: scale(1.05);
    }
  }

  //Last card
  .ui.card:last-child {
    margin-bottom: 5rem;
  }

  //first card
  .ui.card:first-child {
    margin-top: 0;
  }
}

.owner-list-parent{
  h1{
    margin-bottom: 3rem;
    color: black;
    font-size:3rem;
  
  }
}