.signUp {
  margin: 5% auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  width: 25rem;
  padding-bottom: 1%;
  font-size: 1.2rem;
  background:#e5e5e5;
  box-shadow: 5px 1px 20px #111;
  
  h3 {
    font-size: 2rem;
  }

  img {
    width: 100%;
    margin-bottom: 2%;
  }

  a {
    text-decoration: none;
  }

  p {
    text-align: left;
    margin: 0;
    padding: 5px;
  }

  .checkbox {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;
  }
}
